var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{staticClass:"pa-0",attrs:{"fluid":""}},[_c('v-img',{staticClass:"d-flex align-center justify-center",attrs:{"src":_vm.$vuetify.breakpoint.mdAndUp
        ? require('@/assets/images/HomePage/NewsSection.png')
        : require('@/assets/images/HomePage/NewsSection_Mobile.png'),"max-height":"380px","min-height":"280px","height":_vm.$vuetify.breakpoint.mdAndUp ? '' : '280px'}},[_c('div',{staticClass:"d-flex flex-column align-center justify-center my-auto",staticStyle:{"gap":"24px"}},[_c('span',{staticClass:"bold text-center",class:_vm.responsiveClass('h2', 'h3', 'title-1') +
          ' ' +
          (_vm.$vuetify.theme.dark
            ? 'onSurface_dark--text'
            : 'background_light--text')},[_vm._v(_vm._s(_vm.$t("home.discover")))]),_c('v-btn',{staticClass:"regular px-4 rounded-lg",class:_vm.$vuetify.breakpoint.lgAndUp ? 'title-2' : 'subtitle-1',attrs:{"elevation":"0","height":_vm.responsiveClass(48, 44, 34),"color":"primary"},on:{"click":function($event){return _vm.routerPush('Press')}}},[_vm._v(_vm._s(_vm.$t("home.btnNews")))])],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }