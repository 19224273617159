var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{staticClass:"pa-0",attrs:{"fluid":""}},_vm._l((_vm.datas),function(data){return _c('div',{key:data.id,staticClass:"pa-0 d-flex",class:data.isReverse
        ? _vm.$vuetify.breakpoint.mdAndUp
          ? 'flex-row-reverse'
          : 'flex-column-reverse'
        : _vm.$vuetify.breakpoint.mdAndUp
        ? 'flex-row'
        : 'flex-column-reverse align-end',style:(_vm.$vuetify.breakpoint.mdAndUp
        ? 'margin-bottom: 160px'
        : 'margin-bottom: 80px')},[_c('div',{staticClass:"d-flex",class:data.isReverse
          ? _vm.responsiveClass('ml-1', 'ml-3', 'ml-4') + ' justify-start'
          : _vm.responsiveClass('mr-1', 'mr-3', 'mr-4') + ' justify-end',style:(_vm.responsiveClass(
          `margin: 154px ${data.isReverse ? '5.21vw' : '0'} 154px ${
            data.isReverse ? '0' : '5.21vw'
          }`,
          `margin: 154px ${data.isReverse ? '12px' : '0'} 154px ${
            data.isReverse ? '0' : '12px'
          }`,
          '',
        ))},[_c('div',{staticClass:"my-auto d-flex flex-column onSurface_dark--text",class:data.isReverse ^ _vm.$vuetify.breakpoint.mdAndUp
            ? 'align-start text-start'
            : 'align-end text-end',style:(_vm.responsiveClass(
            `gap:40px; min-width:${data.width ? data.width : '530'}px`,
            `gap:40px; min-width:${data.mdWidth ? data.mdWidth : '320'}px`,
            'gap:12px',
          ))},[_c('span',{class:_vm.responsiveClass('h1 bold', 'h2 bold', 'h3 bold')},[_vm._v(_vm._s(_vm.$t("home." + data.title)))]),_c('span',{class:_vm.$vuetify.breakpoint.xs
              ? 'subtitle-2'
              : _vm.responsiveClass('h3', 'title-2 regular', 'title-2 regular')},[_vm._v(_vm._s(_vm.$t("home." + data.subtitle1))),_c('br'),_vm._v(_vm._s(_vm.$t("home." + data.subtitle2)))]),_c('v-btn',{staticClass:"px-4 regular rounded-lg",class:_vm.$vuetify.breakpoint.mdAndUp ? 'title-2' : 'subtitle-1',attrs:{"elevation":"0","color":"primary","height":_vm.$vuetify.breakpoint.mdAndUp ? 44 : 34},on:{"click":function($event){return _vm.buttonPressed(data.btn)}}},[_vm._v(" "+_vm._s(_vm.$t("home." + (data.btn.title ? data.btn.title : "btnGo"))))])],1)]),_c('v-spacer',{style:(_vm.$vuetify.breakpoint.mdAndUp ? '' : 'height:24px')}),_c('v-img',{style:(data.isReverse
          ? `border-radius: 0 ${
              data.imgRound.up
                ? _vm.$vuetify.breakpoint.mdAndUp
                  ? '300px'
                  : '110px'
                : '0'
            } ${
              data.imgRound.down
                ? _vm.$vuetify.breakpoint.mdAndUp
                  ? '300px'
                  : '110px'
                : '0'
            } 0`
          : `border-radius: ${
              data.imgRound.up
                ? _vm.$vuetify.breakpoint.mdAndUp
                  ? '300px'
                  : '110px'
                : '0'
            } 0 0 ${
              data.imgRound.down
                ? _vm.$vuetify.breakpoint.mdAndUp
                  ? '300px'
                  : '110px'
                : '0'
            }`),attrs:{"height":_vm.responsiveClass(
          data.height ? data.height : '600px',
          '600px',
          data.mobileHeight ? data.mobileHeight : '220px',
        ),"max-width":_vm.$vuetify.breakpoint.mdAndUp ? '59vw' : '345px',"src":require(`@/assets/images/HomePage/${data.image}.png`),"position":data.imgPos ? data.imgPos : 'center center'}})],1)}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }