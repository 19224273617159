var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{staticClass:"pa-0",attrs:{"fluid":""}},[_c('v-img',{staticClass:"white--text align-center d-flex",attrs:{"height":_vm.$vuetify.breakpoint.mdAndUp ? '100vh' : '95vh',"src":_vm.$vuetify.breakpoint.mdAndUp
        ? require('@/assets/images/HomePage/Landing.png')
        : require('@/assets/images/HomePage/Landing_Mobile.png')}},[_c('div',{staticClass:"d-flex flex-column"},[_c('v-spacer'),_c('div',{staticClass:"d-flex flex-column ma-auto align-center",style:(_vm.$vuetify.breakpoint.mdAndUp ? 'gap: 20px' : 'gap:12px')},[_c('span',{staticClass:"bold text-center",class:(_vm.$vuetify.theme.dark
              ? 'onSurface_dark--text'
              : 'background_light--text') +
            ' ' +
            (_vm.$vuetify.breakpoint.lgAndUp
              ? 'h1'
              : _vm.$vuetify.breakpoint.mdAndUp
              ? 'h2'
              : 'h3')},[_vm._v(_vm._s(_vm.$t("home.subtitle")))]),_c('v-img',{attrs:{"max-height":_vm.$vuetify.breakpoint.lgAndUp
              ? '101px'
              : _vm.$vuetify.breakpoint.mdAndUp
              ? '70px'
              : '55px',"width":"100%","contain":"","src":_vm.logoSrc}})],1),_c('v-spacer'),_c('v-overlay',{staticClass:"d-flex flex-column justify-end pb-3",class:_vm.$vuetify.breakpoint.mdAndUp ? 'pb-3' : 'pb-2',attrs:{"absolute":"","opacity":"0"}},[_c('div',{staticClass:"d-flex flex-column pa-3 align-center",class:_vm.$vuetify.theme.dark
              ? 'onSurface_dark--text'
              : 'background_light--text',style:(_vm.$vuetify.breakpoint.mdAndUp ? 'gap: 8px' : 'gap:14px')},[_c('span',{staticClass:"text-center",class:_vm.$vuetify.breakpoint.mdAndUp ? 'h3 bold' : 'title-1 bold'},[_vm._v(_vm._s(_vm.$t("home.explore")))]),_c('v-img',{attrs:{"width":_vm.$vuetify.breakpoint.mdAndUp ? 40 : 22,"src":require("@/assets/icons/Mouse.svg")}})],1)])],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }